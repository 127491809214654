import React, {FunctionComponent} from "react"
import  "./textAreaStyle.css"



type props ={
    placeholder:string
    onChange:any
    name:string
    label:string
}

export const TextArea: FunctionComponent<props> = ({placeholder, onChange, name, label}) => {
    return (
        <div className={"textArea__container"}>
            <p style={{color:"#283847"}}>{label}</p>
            <textarea  inputMode={"text"}  name={name} placeholder={placeholder} onChange={onChange} className={"textArea__input"}  />
        </div>

    )
}