import React, {FunctionComponent, useEffect, useState} from "react"
import {NavBar} from "../NavBar";
import "./headerStyle.css"
import {FaFacebookF, FaTwitter, FaInstagram} from "react-icons/fa";
import {Link} from "react-router-dom";
import {GrMenu} from "react-icons/gr";
import {FiMenu, FiYoutube} from "react-icons/fi";
import {AiOutlineYoutube} from "react-icons/ai";
import {RxCross1} from "react-icons/rx";
import {useLocation} from "react-router-dom"
import logo from "../../assets/logo.png"


export const Header: FunctionComponent = () => {
    const [click, setClick] = useState(false)
    const handleClick =()=>setClick(!click)
    const closeMobileMenu = ()=>setClick(false)

    const location = useLocation()

    useEffect(()=>{
        if(location.hash){
            document.getElementById(location.hash.substring(1))?.scrollIntoView({behavior:"smooth", inline:"start"})
        }else{
            window.scrollTo(0,0)
        }
    }, [location])

    return (
        <div className={"header lg__container-padding"}>
            <div>
                <Link to={"/"}>
                    {/*<h1>ADIT</h1>*/}
                    <img src={logo} alt={""} className={"header__logo"} />
                </Link>

            </div>
            <NavBar cln={click ? 'nav-menu active' : 'nav-menu'} onClick={handleClick} />
            <div className={"header__socialMedia"}>

                <a href={"https://youtube.com/@aditdiasporatshopo4543"}  target={"_blank"} className={"sm__icon-hover"}>
                    <FiYoutube  />
                </a>
                <div className={"sm__icon-hover"}>
                    <FaFacebookF  />
                </div>
                <div className={"sm__icon-hover"}>
                    <FaTwitter />
                </div>

            </div>
            <div className={"hamburger__menu"} onClick={handleClick}>
                {
                    click? <RxCross1 className={"menu__icon"} />:<FiMenu className={"menu__icon"} />
                }

            </div>
        </div>
    )
}