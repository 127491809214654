import React, {FunctionComponent} from "react"
import "./navFooterStyle.css"
import {Link, NavLink} from "react-router-dom";


export const NavFooter: FunctionComponent = () => {
    return (
        <div className={"navFooter"}>
            <nav className={"navFooter-Bar"}>
                <ul className={"navFooter-container"}>
                    <li className={"navFooter-item"}>
                        <Link to={"/"} className={"navFooter-links"}>Accueil</Link>
                    </li>

                    <li className={"navFooter-item"}>
                        <Link to={"/about"} className={"navFooter-links"}>A propos</Link>
                    </li>

                    <li className={"navFooter-item"}>
                        <Link to={"/contact"} className={"navFooter-links"}>Contact</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}