import React, {FunctionComponent} from "react"
import "./footerStyle.css"
import {NavFooter} from "../NavFooter";
import logo from "../../assets/logo.png";


export const Footer: FunctionComponent = () => {

    return (
        <div className={"footer lg__container-padding"}>
            <div className={"footer__logo-links"}>
                <div className={"footer__logo"}>
                    <img src={logo} alt={""} className={"header__logo"} />
                    <p>Association pour le Développement Intégral de la Tshopo</p>
                </div>
                <div className={"footer__links"}>
                    <div>
                        <h2 className={"titles__spacing"}>Liens utiles</h2>
                        <NavFooter />
                    </div>

                </div>
                <div className={"footer__links"}>

                    <div>
                        <h2 className={"titles__spacing"}>Informations</h2>
                        <div className={"footer__useful-info"}>
                            <p>37270 Montlouis-sur-Loire / France</p>
                            <p>
                                <a href={"tel:+33666914606"}> +33 6 66 91 46 06</a>
                            </p>
                            <p>
                                <a href={"mailto:contact@adit-diaspora.com"}> contact@adit-diaspora.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}