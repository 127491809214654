import React, {FunctionComponent} from "react"
import "./bannerStyle.css"



type props ={
    pageTitle:string
    banner:any
}

export const Banner: FunctionComponent<props> = ({banner, pageTitle}) => {
    return (
        <>
            <img src={banner} alt={""} className={"banner__img"} />
            <div className={"banner__overlay"}>
                <h1>{pageTitle}</h1>
            </div>

        </>
    )
}