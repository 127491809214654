import React, {FunctionComponent} from "react"
import "./selecInputStyle.css"


type props ={
    label:string
    type?: string
    name:string
    onChange:any
}

export const SelectInput: FunctionComponent<props> = ({label, name, onChange, type}) => {
    return (
        <div className={"select__container"}>
            <p style={{color:"#283847"}}>{label}</p>
            <select required name={name} className={"select__input"} onChange={onChange} >
                <option  value={""}>Choisir l'objet</option>
                <option value={"information"}>Demande d'information</option>
                <option value={"contribution"}>Je veux contribuer</option>
                <option value={"membership"}>Devenir membre</option>
            </select>

        </div>
    )
}