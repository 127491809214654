import React, {FunctionComponent} from "react"
import "./homePageStyle.css"
import hero from "../../assets/ppp.jpg"
import rel1 from "../../assets/rel1.jpg"
import rel2 from "../../assets/rel2.jpg"
import rel3 from "../../assets/rel3.jpg"
import rel7 from "../../assets/rel7.jpg"
import rel8 from "../../assets/rel8.jpg"
import rel6 from "../../assets/rel6.jpg"
import about from "../../assets/about.jpg"
import you from "../../assets/you.jpg"
import r1 from "../../assets/r1.jpg"
import r2 from "../../assets/r2.jpg"
import r3 from "../../assets/r3.jpg"
import hom1 from "../../assets/hom1.jpg"
import hom2 from "../../assets/hom2.jpg"
import hom3 from "../../assets/hom3.jpg"
import hom5 from "../../assets/hom6.jpg"
import adit1 from "../../assets/aditnew1.jpg"
import adit2 from "../../assets/aditnew2.jpg"
import adit3 from "../../assets/aditnew3.jpg"
import depistage1 from "../../assets/depistage1.jpg"
import depistage2 from "../../assets/depistage2.jpg"
import depistage3 from "../../assets/depistage3.jpg"

// import adit1 from "../../assets/aditnew1.jpg"
import {Button} from "../../components/Button";
import {Statistic} from "../../components/Statistic";
import {BsBuildings} from "react-icons/bs";
import {BiUser} from "react-icons/bi";
import {GiSandsOfTime} from "react-icons/gi";
import {Section} from "../../components/Section";
import together from "../../assets/together.svg"
import people from "../../assets/help.jpg"
import YouTubePlayer from "../../components/YoutubePlayer";



export const HomePage: FunctionComponent = () => {
    return (
        <div className={"home"}>
            <div className={"home__hero-section"}>
                <div className={"hero__contents"}>
                    <h1 className={"home__hero-title"}>Tout donner pour la TSHOPO.</h1>
                    <div>
                        <p className={"hero__paragraph"}>
                            <span className={"emphasize"}>Association</span> pour le <span className={"emphasize"}>Développement</span> <span className={"emphasize"}>Intégral</span> de la <span className={"emphasize"}>Tshopo</span> a
                            pour but de contribuer au développement social et économique
                            de la province de la <span className={"emphasize"}>Tshopo</span>.
                        </p>

                        <div className={"content__divider"}  />
                        <Button buttonSize={"btn--medium"}>Nous contacter</Button>
                    </div>
                    <div className={"home__data"}>
                        <Statistic icon={<BsBuildings className={"statistic__icon"} />} data={10} title={"projets"} />
                        <Statistic icon={<BiUser className={"statistic__icon"} />} data={30} title={"membres"} />
                        <Statistic icon={<GiSandsOfTime className={"statistic__icon"} />} data={5} title={"ans"} />
                    </div>
                </div>
                <div className={"hero__container"}>
                    <img src={hero} alt={""} className={"home__hero-img"} />
                </div>
            </div>
            <Section>
                <div className={"home__sectionOne-container lg__container-padding"}>
                    <div>
                        <h1 className={"section__title"}>Batir ensemble</h1>

                        <p className={"section__paragraph"}>
                            Cette initiative concerne  tout <span className={"emphasize"}>boyomais</span>   de l'étranger  ainsi
                            les personnes  non <span className={"emphasize"}>boyomaises</span>  qui  considèrent  la
                            <span className={"emphasize"}> Tshopo </span>
                            province  comme  une seconde  province.
                        </p>

                        <p className={"section__paragraph"}>
                            Monsieur <span className={"emphasize"}>Bony ISAMENE</span>  a reçu cette recommandation de la part de Dieu de réunir ses frères et sœurs de la diaspora et se mettre ensemble pour aider leur province d’origine.
                            Notre présence à l’extérieur n’était pas un hasard, <span className={"emphasize"}>ADIT</span> a une mission divine.
                        </p>

                        <h1 className={"section__title"}>Mission</h1>

                        <p className={"section__paragraph"}>
                            Je vous ai montré de toutes manières que c'est en
                            <span className={"emphasize"}> travaillant</span> ainsi qu'il faut <span className={"emphasize"}>soutenir </span>
                            les <span className={"emphasize"}>faibles</span>, et se rappeler les paroles du
                            <span className={"emphasize"}> Seigneur</span>, qui a dit lui-même:  <span className={"emphasize"}>Il y a plus de bonheur à donner qu'à recevoir. </span>
                            Actes 20:35

                        </p>
                    </div>
                    <div>
                        <img src={together} alt={""} className={"sectionOne__pic"} />
                    </div>
                </div>
            </Section>

            <Section>
                <div style={{backgroundColor:"#0C4A6E"}}>
                    <div className={"home__sectionTwo-container lg__container-padding"}>
                        <div className={"mission__card"}>
                            <h2 className={"section__title"} style={{color:"white"}}>{"Qui nous sommes".toUpperCase()}</h2>
                            <p style={{color:"#fff"}}>
                                Nous sommes Congolais et Congolaises ressortissants de la province de la <span className={"emphasize"}>Tshopo </span> et vivons à l'étranger.
                            </p>
                        </div>
                        <div className={"mission__card"}>
                            <h2 className={"section__title"} style={{color:"white"}}>{"Ce que nous faisons".toUpperCase()}</h2>
                            <p style={{color:"#fff"}}>
                                Nos axes principaux
                                d'intervention  sont les <span className={"emphasize"}>personnes  vulnerables </span>,  comme  des personnes  de 3ème âge.
                            </p>
                        </div>
                        <div className={"mission__card"}>
                            <h2 className={"section__title"} style={{color:"white"}}>{"Pourquoi le faire".toUpperCase()}</h2>
                            <p style={{color:"#fff"}}>
                                Parce que nous aimons notre province et voulons y laisser des <span className={"emphasize"}>empreintes d'amour</span>
                                , de <span className={"emphasize"}>paix</span> et de <span className={"emphasize"}>bonheur</span> pour le bien de tous.
                            </p>
                        </div>

                    </div>
                </div>

            </Section>
            <Section>

                <div className={"home__helpSection-container lg__container-padding"}>
                    <div>
                        <img src={people} alt={""} className={"helpSection__pic"} />
                    </div>
                    <div>
                        <h1 className={"section__title"}>Comment nous soutenir?</h1>
                        <p className={"section__paragraph1"}>
                            Nous demandons aux gens des bonnes volontés de nous venir en aide pour les réalisations des projets <span className={"emphasize"}>ADIT</span>. Nous aurons besoin de tous
                        </p>
                        <div  style={{height:'2rem'}}/>
                        <Button buttonSize={"btn--medium"}>Soutenir</Button>
                    </div>

                </div>

            </Section>
            <Section>
                <div>
                    <h2 className={"section__title"} style={{textAlign:"center"}}>Quelques réalisations</h2>
                </div>
                <div className={"home__realization-section"}>
                    <img src={r1} alt={""} className={"realization__picture"} />
                    <img src={r2} alt={""} className={"realization__picture"} />
                    <img src={r3} alt={""} className={"realization__picture"} />
                </div>
                <div className={"other__realiz lg__container-padding"}>
                    <div>
                        <div className={"home__gallery-paragraph"}>
                            <p className={"hero__paragraph"}>
                                Consultation mensuelle chez les personnes de 3ème âge, home de vieillard 16ème avenue
                                tshopo à Kisangani
                            </p>
                        </div>
                        <div className={"home__realization-section"}>
                            <img src={rel7} alt={""} className={"otherRealization__picture"}/>
                            <img src={rel8} alt={""} className={"otherRealization__picture"}/>
                            <img src={rel3} alt={""} className={"otherRealization__picture"}/>
                        </div>
                    </div>
                    <div>
                        <div className={"home__gallery-paragraph"}>
                            <p className={"hero__paragraph"}>
                                Une campagne de dépistage effectuée au marché central de Kisangani
                            </p>
                        </div>
                        <div className={"home__realization-section"}>
                            <img src={rel6} alt={""} className={"otherRealization__picture"}/>
                            <img src={rel1} alt={""} className={"otherRealization__picture"}/>
                            <img src={rel2} alt={""} className={"otherRealization__picture"}/>
                        </div>
                    </div>

                    <div className={"item1"}>
                        <div className={"home__gallery-paragraph"}>
                            <p className={"hero__paragraph"}>
                                Remise officielle du bâtiment réhabilité, home de vieillard 16ème
                                avenue tshopo à Kisangani, en présence des représentant du gouvernement
                                et d'autres personnalités établies localement.
                                Coût de réhabilitation <span className={"emphasize"}> 4700$</span>
                            </p>
                        </div>

                        <div className={"home__realization-section"}>
                            <img src={hom1} alt={""} className={"otherRealization__picture"}/>
                            <img src={hom2} alt={""} className={"otherRealization__picture"}/>
                            <img src={hom3} alt={""} className={"otherRealization__picture"}/>
                            <img src={hom5} alt={""} className={"otherRealization__picture"}/>
                            <img src={you} alt={""} className={"otherRealization__picture"}/>
                            <img src={about} alt={""} className={"otherRealization__picture"}/>
                        </div>

                    </div>
                    <div className={"item1"}>
                        <div className={"home__gallery-paragraph"}>
                            <p className={"hero__paragraph"}>
                                Distribution de sel aux vulnérables du territoire de Ubundu et accompagnement sur le dépistage du diabète  sucré, les bénéficiaires remercient l'association pour les efforts consentis pour ces viellards .
                            </p>
                        </div>

                        <div className={"home__realization-section"}>
                            <img src={adit1} alt={""} className={"otherRealization__picture"}/>
                            <img src={adit2} alt={""} className={"otherRealization__picture"}/>
                            <img src={adit3} alt={""} className={"otherRealization__picture"}/>
                            <img src={depistage1} alt={""} className={"otherRealization__picture"}/>
                            <img src={depistage2} alt={""} className={"otherRealization__picture"}/>
                            <img src={depistage3} alt={""} className={"otherRealization__picture"}/>
                            {/*<img src={you} alt={""} className={"otherRealization__picture"}/>*/}
                            {/*<img src={about} alt={""} className={"otherRealization__picture"}/>*/}
                        </div>

                    </div>
                </div>
            </Section>
            <div className={"lastSection"}>
                <div>
                    <h2 className={"section__title"} style={{textAlign: "center", paddingTop: '1rem'}}>Media</h2>
                </div>

                <div className={"lastSection__container lg__container-padding"}>


                    <div className={"item1"}>


                        <div className={"video-section"}>
                            <YouTubePlayer videoId={"BLgzXeBtMFo"}/>
                            <YouTubePlayer videoId={"gCGuNMvqXr0"}/>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}
